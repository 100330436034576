<template>
    <div class="contaners">
      <div @click="mineMessage" class="messageTitle">
            <img class="messageIcon" src="../../assets/phone2.png" />
            <div>联系客服</div>
          </div>
        <!-- <img src="../../assets/content_img.png" /> -->
        <div class="from_box">
            <div class="from_info">提示：以下填写资料为《中华全国总工会会员证》所需资料，请根据实际填写！</div>
            <div class="from_title">工会报名物料收集</div>
            <div class="example">
              <div>申请人照片</div>
              <div class="example_text" @click="explam">点击查看示例></div>
            </div>
            <div class="identityCard">
              <div class="box_img">
                <uploadImg :isNumber="isNumber" :isUser="isUser" :uploadData="uploadData" @uploadImg="uploadImgOne"></uploadImg>
              </div>
            </div>
            <div class="text">提示:上传照片为1寸红底冠冕照片,大小不的超过10M</div>
            <div class="id_img margins">
              <uploadImg :isNumber="isNumber1" :idCard="idCard" :uploadData="uploadData1" @uploadImg="uploadImgTwo"></uploadImg>
            </div>
            <div class="id_img">
                <uploadImg :isNumber="isNumber1" :idCard="idCard" :uploadData="uploadData1" @uploadImg="uploadImgThree"></uploadImg>
            </div>
            <div class="bottom_text">提示:请确保照片边角完整</div>
            <div style="display:flex">
               <div class="submit" @click="lastStep">上一步</div>
               <div class="submit" @click="nethSubmit">下一步</div>
            </div>
        </div>

        <div class="pop_box" v-if="cloneImgShow">
          <div class="pop_img">
            <img src="../../assets/explam.png"/>
          </div>
          <img  @click="cloneBtn" class="cloneImg" src="../../assets/cloneIcon.png" />
        </div>
      <!-- 提示框 -->
      <toast v-if="toastShow" :config="configData"></toast>
    </div>
</template>
<script type="">
// let form = {
//     username:'',
//     nation:''
// }

import * as apiCommon from "@/api/common";
import  toast from "@/components/toast";
// import wx from "weixin-js-sdk";
import { Toast } from 'vant';
import uploadImg from "../../components/uploadImg";
export default {
  
  data() {
    return {
      // 上传图片
      isNumber:true,
      isUser:true,
      idCard:true,
      isNumber1:false,
      uploadData: {
        getToken: "",
        imgConfig: "{ useCdnDomain: true }",
        num:1
      },
      uploadData1: {
        getToken: "",
        imgConfig: "{ useCdnDomain: true }",
        num:1,
        
      },
       cloneImgShow:false,
       fileList:[
         
      ],
       fileList1:[],
       fileList2:[],
       imgToken:'',
       imgConfig: "{ useCdnDomain: true }",
       toastShow:false,
       configData:{
         title:''
       },
       payStatus:'',
       from: null,
      from1: null,
      from2: null,
    };
  },
  components: {
    toast,
    uploadImg
  },
  created() {
    document.title = "工会报名物料收集";
    this.getToken();

    this.settingConfig();
    this.from = localStorage.getItem("imgName");
    this.from1 = JSON.parse(localStorage.getItem("temp2"));
    // this.from2 = JSON.parse(localStorage.getItem("img"));
    if(!JSON.parse(localStorage.getItem("img"))){
      this.from2 = JSON.parse(localStorage.getItem("img"));
      this.fileList = this.from2[0]
      this.fileList1 = this.from2[1]
      this.fileList2 = this.from2[2]
    }
  },
  methods: {
    uploadImgOne(img){
      this.fileList = img
    },
    uploadImgTwo(img){
      this.fileList1 = img
    },
    uploadImgThree(img){
      this.fileList2 = img
    },
    oversize(){
        this.toastShow = true;
          this.configData.title = "图片太大了";
          setTimeout(() => {
            this.toastShow = false
          }, 1000);
    },
     // 获取配置
    async settingConfig(){
      let data  ={
        config:["union_member_fee"]
      }
      let res = await apiCommon.configSetting(data);
      if(res.code==0){
        this.payStatus = res.data.union_member_fee
        console.log(res) 
      }
    },
     async getToken() {
      let res = await apiCommon.getImgToken();
      if (!res || typeof res === "string" || res.error)
        return this.$toast.fail(res ? res.error || res : "获取七牛云信息失败!");
      this.imgToken = res.data;
      this.uploadData1.imgToken = res.data;
      this.uploadData.imgToken = res.data;
      console.log(this.imgToken, "1223131");
    },
    // 上传图片
     // 返回 Promise
    beforeRead(e) {
      // Toast.loading({
      //   message: "上传中...",
      //   forbidClick: true,
      //   duration: 0
      // });
      
      console.log(e,"file");
        let img = e
        this.upload(1,img)
    },
    beforeRead1(e) {
      Toast.loading({
        message: "上传中...",
        forbidClick: true,
        duration: 1000
      });
      console.log(e,"file");
        let img = e
        this.upload(2,img)
    },
    beforeRead2(e) {
      Toast.loading({
        message: "上传中...",
        forbidClick: true,
        duration: 1000
      });
      console.log(e,"file");
        let img = e
        this.upload(3,img)
    },
    async upload(index,img){
      let fd = new FormData();
      fd.append("token", this.imgToken);
      fd.append("config", this.imgConfig);
      fd.append("file", img);

      let imgRes = await apiCommon.uploadImg(fd);

      this.imgUrl = "https://cdn.health.healthplatform.xyz/" + imgRes.key;
      if(index==1){
        this.fileList=[{url:this.imgUrl}];
      }else if(index==2){
        this.fileList1 = [{url:this.imgUrl}];
      }else{
        this.fileList2 = [{url:this.imgUrl}];
      }
      Toast.clear()
    },
    imgBtnClone(index){
      if(index==0){
        this.fileList = [];
      }else if(index==1){
        this.fileList1 = [];
      }else{
        this.fileList2 = [];
      }
    },
    explam(){
      this.cloneImgShow = true
    },
    onSubmit(values) {
      console.log('submit', values);
    },
    cloneBtn(){
      this.cloneImgShow = false
    },
    nethSubmit(){
      console.log("this.payStatus")
      if(this.payStatus!=0){
        console.log(2)
           let img = [this.fileList, this.fileList1,this.fileList2];
      for(var i=0; i<img.length;i++){
        if(img[i].length==0){
          this.toastShow = true;
          this.configData.title = "请上传图片";
          setTimeout(() => {
            this.toastShow = false
          }, 1000);
          return false;
        }
      }
      localStorage.setItem("img",JSON.stringify(img));
      this.$router.push("/lador/pay")
      }else{
        // 直接提交审核
        this.pauAudit()
      }
     
    },

     async pauAudit(){
         let data = {
        audit_type: 1,
        audit_info: {
          username: this.from1.username.value,
          sex: this.from1.sex.value,
          nation: this.from1.nation.value,
          politics: this.from1.politics.value,
          education: this.from1.education.value,
          identity: this.from1.identity.value,
          phone: this.from1.phone.value,
          address: this.from1.address.value,
          experience: this.from1.experience.value,
          remark: this.from1.remark.value,
          signature: this.from,
          userImg: this.fileList[0].url,
          identityCard_front: this.fileList1[0].url,
          identityCard_reverse: this.fileList2[0].url
        },
        pay_type: 3
      };
       let res = await apiCommon.orderPay(data);
      console.log(res);
      if(res.code==0){
        this.toastShow = true;
          this.configData.title = "提交成功";
          localStorage.removeItem("imgName");
          localStorage.removeItem("temp2");
          // localStorage.removeItem("img");
          setTimeout(() => {
            this.toastShow = false;
          }, 1000);
          setTimeout(() => {
            this.$router.push(`./orderDetail?id=${res.data.audit.audit_id}`);
          },1000)
          
      }else{
        this.toastShow = true;
          this.configData.title = res.error;
          setTimeout(() => {
            this.toastShow = false;
          }, 1000);
      }
    },
    lastStep(){
      this.$router.go(-1)
    },
      mineMessage() {
      console.log(1, "2");
      //       wx.config({
      //    debug: false,
      //    appId: '',
      //    timestamp: ,
      //    nonceStr: '',
      //    signature: '',
      //    jsApiList: [
      //      // 所有要调用的 API 都要加到这个列表中
      //      'getLocation',
      //      'hideOptionMenu',
      //      'closeWindow'
      //    ]
      //  });
      // wx.ready(function() {
      // wx.closeWindow();
      this.$router.push("./service")
      // });
    },
  }
};
</script>
<style>
.van-toast {
  width: 300px;
  min-height: 120px;
}
.van-toast__text {
  font-size: 35px;
}
.van-icon {
  font-size: 40px;
}
.van-dialog {
  width: 500px;
  font-size: 35px !important;
}
.van-dialog__message {
  font-size: 25px;
  line-height: 40px;
}
.messageTitle .messageIcon {
  width: 36px;
  height: 36px;
  margin-right: 20px;
}
.messageTitle {
  text-align: right;
  margin-right: 20px;
  font-size: 24px;
  margin-top: 20px;
  position:absolute;
  z-index: 10;
  top: 30px;
  right: 20px;
  color: #fff;
}
.box_img{
  position: relative;
  width:100%;height: 100%
}
.box_img img{
  width:126px;
  height:126px
}
.box_clone {
  width: 40px;
  border-radius: 40px;
  background-color: #fff;
  position: absolute;
  top: 0;right: 0
}
.box_clone .imgs{
  width: 30px;height: 30px;
  border-radius: 30px;
  margin: 5px
  
}
.imgFlexd{
  position:absolute;
  left: 0;
  top: 0px;
  z-index: 2;
  width: 100%;
  height: 100%;
}
.van-uploader__wrapper{
  width: 100%;
  height: 100%
}
.van-uploader__preview{
  width: 100%;
  height: 100%
}
.van-uploader__preview-image{
  width: 100%;
  height: 100%
}
.van-uploader__upload{
  width: 100%;
  height: 100%
}
.van-uploader__input{
  bottom: 30px;
  left: 30px
}
.van-icon-photograph:before{
  content: ""
}
.header_btn {
  font-size: 50px;
  font-family: PingFang SC;
  font-weight: 800;
  color: #ffffff;
  opacity: 1;
  text-align: center;
  margin: 40px auto;
}

.van-uploader__preview-delete{
  width: 25px;
  height: 25px
}
.content {
  position: absolute;
  top: 30px;
  left: 0;
}
.contaners {
  width: 100%;
  height: 1600px;
}
.contaners>img {
  width: 100%;
  height: 100%;
}
.from_box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1600px;
}
.from_info {
  font-size: 20px;
  color: #fff;
  margin-top: 20px;
  text-align: center;
}
.from_title {
  font-size: 50px;
  color: #fff;
  text-align: center;
  margin: 34px auto;
}
 .example{
        display: flex;
        justify-content:space-between;
        font-size: 30px;
        color: #fff;
        margin: 45px 26px 0 70px
    }
    .example_text{
      font-size: 20px;
      border-bottom: 1px solid #fff;padding-bottom: 5px
    }
  .identityCard{
    width: 174px;
    height: 171px;
    border: 2px dashed #fff;
    opacity: 1;
    border-radius: 5px;
    margin: 10px auto;
    font-size: 20px;
    text-align: center;
    color: #fff;
  }
  .identityCard .pic{
    width: 103px;height:94px;
    margin: 30px auto 10px; 
    
  }
  .text{
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    opacity: 1;
    text-align: center;
    margin: 17px auto 65px; 
  }
  .id_img{
    width: 417px;height:247px;
    margin: 0 auto 0;
    position: relative;
  }
  .id_img img{
    width: 417px;height:247px
  }
  .margins{
    margin-bottom: 88px
  }
  .bottom_text{
    font-size: 20px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 101px;
    color: #FFFFFF;
  }
  .submit{
    width: 200px;
    height: 60px;
    background-color: #fff;
    border-radius: 30px;text-align: center;
    line-height:60px;
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #2A7BB5;
    opacity: 1;
    margin: 0 auto 0
  }
  .pop_box{
    position: fixed;
    top: 0;
    left: 0;width: 100%;
    height: 100vh;
    z-index: 2;
    background-color: rgba(0,0,0,.6);
  }
  .pop_img{
    width:600px;
    height:1100px;
    margin: 70px auto 0
  }
  .pop_img img{
    width: 100%;height: 100%
  }
  .pop_box .cloneImg{
    width: 20px;
    height: 20px;
    position: absolute;
    top: 110px;right: 100px
  }
  .van-uploader__upload{
    background-color:transparent
  }
  .fileImg{
    background-color:transparent!important;
    /* display: none */
    color: transparent!important;
    width: 174px;height:171px
  }
  .id_img {
  width: 417px;
  height: 247px;
  margin: 0 auto 0;
  border:1px dashed #fff;
  position: relative;
  margin: 30px auto 0;
}
</style>
